import React from "react";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import { Router, Route, Switch } from "react-router-dom";

import "assets/scss/material-kit-react.scss?v=1.8.0";

import ProfilePage from "views/ProfilePage.js";
import Postulante from "views/Postulante.js";
import OcrIt from "views/OcrIt.js";
import PirateWars from "views/PirateWars.js";
import DeadPoetsCohort from "views/DeadPoetsCohort.js";

var hist = createBrowserHistory();

ReactDOM.render(
  <Router history={hist}>
    <Switch>
      <Route path="/postulante" component={Postulante} />
      <Route path="/ocrit" component={OcrIt} />
      <Route path="/piratewars" component={PirateWars} />
      <Route path="/deadpoetscohort" component={DeadPoetsCohort} />
      <Route path="/" component={ProfilePage} />
    </Switch>
  </Router>,
  document.getElementById("root")
);
