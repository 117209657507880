import React from "react";
// react components for routing our app without refresh
import { Link } from "react-router-dom";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import Header from "components/Header.js";
import HeaderLinks from "components/HeaderLinks.js";
import Footer from "components/Footer.js";
import Button from "components/Button.js";
import GridContainer from "components/GridContainer.js";
import GridItem from "components/GridItem.js";
import SectionCarousel from "components/SectionCarousel.js";
import ContactForm from "components/ContactForm.js";
import Parallax from "components/Parallax.js";

import profile from "images/pb.jpg";
// import brandLogo from "images/logo_transparent.png";
import brandLogoBlack from "images/logo_transparent_black.png";

import styles from "assets/jss/material-kit-react/views/profilePage.js";

const useStyles = makeStyles(styles);

export default function ProfilePage(props) {
  const classes = useStyles();
  const { ...rest } = props;
  const imageClasses = classNames(
    classes.imgRaised,
    classes.imgRoundedCircle,
    classes.imgFluid
  );
  // const navImageClasses = classNames(classes.imgRounded, classes.imgGallery);
  return (
    <div>
      <Header
        color="transparent"
        brand={<Link to="/">
          <img src={brandLogoBlack} alt="PBlogo" 
               style={{height: "45px", backgroundColor: "rgb(255,255,255,0.4)", 
                       boxShadow: "0 0 7px 5px rgba(255,255,255,0.4)",
                      //  boxShadow: "0 0 15px 10px rgba(255,255,255,0.4)",
                       borderRadius: "25px"}} />
          </Link>
        }
        rightLinks={<HeaderLinks />}
        fixed
        changeColorOnScroll={{
          height: 200,
          color: "white"
        }}
        {...rest}
      />
      <Parallax small filter image={require("images/bg3.jpg")} />
      <div className={classNames(classes.main, classes.mainRaised)}>
        <div>
          <div className={classes.container}>
            <GridContainer justify="center">
              <GridItem xs={12} sm={12} md={6}>
                <div className={classes.profile}>
                  <div>
                    <img src={profile} alt="..." className={imageClasses} />
                  </div>
                  <div className={classes.name}>
                    <h3 className={classes.title}>Pavel Beletsky</h3>
                    <h6>Full Stack Web Developer</h6>
                    <Button justIcon link className={classes.margin5}
                            href="https://www.linkedin.com/in/pavel-beletsky/"
                            target="_blank">
                      <i className={"fab fa-linkedin"} />
                    </Button>
                    <Button justIcon link className={classes.margin5}
                            href="https://github.com/paul-b19"
                            target="_blank">
                      <i className={"fab fa-github"} />
                    </Button>
                  </div>
                </div>
              </GridItem>
            </GridContainer>
            <div className={classes.description}>
              <p>
                Enthusiastic full-stack web developer, 
                experienced in Ruby on Rails and JavaScript based programming, 
                with a background in software quality assurance and practical experience 
                in Agile software development methodologies, seeks a position 
                to apply skills, learn new technologies and contribute to community.{" "}
              </p>
            </div>
            <div style={{textAlign: "center"}}>
              <h3 className={classes.title}>Technical Skills</h3>
            </div>
            <div className={classes.description}>
              <p>
                Ruby, Rails, SQLite, PostgreSQL, JavaScript, React, Redux, 
                CSS, HTML, Bootstrap, MaterialUi, Heroku, Git, Jira, SDLC, Agile
              </p>
            </div>
            <SectionCarousel />
            <ContactForm />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}
